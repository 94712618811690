import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Column } from "src/app/shared/models/column.model";
import { ResumoDadosGrafico } from "src/app/shared/tos/response/response-resumo-dados-grafico.to";

@Component({
  selector: "app-grafico-resumo-popup",
  templateUrl: "./grafico-resumo-popup.component.html",
  styleUrls: ["./grafico-resumo-popup.component.scss"],
  providers: [DatePipe],
})
export class GraficoResumoPopupComponent implements OnInit {
  @Output() isPopupVisibleChange = new EventEmitter();
  @Input() titulo: string;
  @Input() totalRegistros: number;
  @Input() loading: boolean;
  @Input() dados: ResumoDadosGrafico[];
  @Input() showColunaDtUltimaExecucao = false;
  @Input() labelDtUltimaExecucao = "graficoBemEstar.dtUltExecucao";
  private _isPopupVisible: boolean;
  colunas: Column[];

  get isPopupVisible() {
    return this._isPopupVisible;
  }

  @Input()
  set isPopupVisible(value: boolean) {
    this._isPopupVisible = value;
    this.isPopupVisibleChange.emit(value);
  }

  constructor(private translateService: TranslateService, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.configurarTabelaGraficoBemEstar();
  }

  configurarTabelaGraficoBemEstar() {
    this.colunas = [
      {
        header: this.translateService.instant("graficoBemEstar.nome"),
        field: "dsNome",
      },
      {
        header: this.translateService.instant("graficoBemEstar.matricula"),
        field: "cdMatricula",
        textHeaderAlign: "center",
        textAlign: "center",
      },
      {
        header: this.translateService.instant("graficoBemEstar.dsHierarquia"),
        field: "dsHierarquia",
        styleClass: () => "hierarquia-class",
        tooltipValue: (v) => v.dsHierarquia,
      },
      {
        header: this.translateService.instant("graficoBemEstar.total"),
        field: "qtRegistros",
        textHeaderAlign: "center",
        textAlign: "center",
      },
    ];

    if (this.showColunaDtUltimaExecucao) {
      this.colunas.push({
        header: this.translateService.instant(this.labelDtUltimaExecucao),
        field: "dtUltExecucao",
        valueDisplayFn: (data) => this.datePipe.transform(data, "short"),
        textHeaderAlign: "center",
        textAlign: "center",
        width: "40%",
      });
    }
  }
}
