import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { SelectItem, TreeNode } from "primeng/api";
import { CargoService } from "src/app/shared/api-services/cargo.service";
import { HierarquiaService } from "src/app/shared/api-services/hierarquia.service";
import { TurnoService } from "src/app/shared/api-services/turno.service";
import { CriterioInsercaoService } from "src/app/shared/services/criterio-insercao.service";
import { Util } from "src/app/shared/services/util.service";
import { ParamsSinalizadorProntidao } from "src/app/shared/tos/request/params-sinalizador-prontidao";

@Component({
  selector: "app-filtros-sinalizador-prontidao",
  templateUrl: "./filtros-sinalizador-prontidao.component.html",
  styleUrls: ["./filtros-sinalizador-prontidao.component.scss"],
})
export class FiltrosSinalizadorProntidaoComponent implements OnInit {
  @ViewChild("f") form: NgForm;
  @Output() filtrar = new EventEmitter<ParamsSinalizadorProntidao>();
  @Input() isCompilado: boolean;
  @Input() filtrosIniciais: ParamsSinalizadorProntidao;

  showFiltros = false;
  filtrosIniciaisSetados = false;
  criteriosInsercaoPreDefinidos: any[];

  periodos: string[] = ["DIAS_30", "DIAS_60", "DIAS_90", "DIAS_120"];

  constructor(private criterioInsercaoService: CriterioInsercaoService) {}

  ngOnInit(): void {
    this.utilizarCriterioInsercaoPreDefinido();
  }

  utilizarCriterioInsercaoPreDefinido() {
    this.criteriosInsercaoPreDefinidos = this.criterioInsercaoService.getValoresPreDefinidos();
  }

  handleSubmit(form: NgForm) {
    if (form.valid) {
      const filtros: ParamsSinalizadorProntidao = {
        ...form.value,
        idHierarquia: Array.isArray(form.value.idHierarquia)
          ? form.value.idHierarquia
          : [form.value.idHierarquia],
      };
      this.filtrar.emit(filtros);
      this.fecharFiltros();
    }
  }

  setFiltrosIniciais() {
    let idHierarquia;
    if (this.isCompilado) {
      idHierarquia = Array.isArray(this.filtrosIniciais.idHierarquia)
        ? this.filtrosIniciais.idHierarquia[0]
        : this.filtrosIniciais.idHierarquia;
    } else {
      idHierarquia = Array.isArray(this.filtrosIniciais.idHierarquia)
        ? this.filtrosIniciais.idHierarquia
        : [this.filtrosIniciais.idHierarquia];
    }
    this.form.form.patchValue({ ...this.filtrosIniciais, ...idHierarquia });
  }

  open() {
    this.showFiltros = true;
    this.setFiltrosIniciais();
  }

  fecharFiltros() {
    this.showFiltros = false;
  }
}
