import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from "@angular/core";
import { Chart } from "angular-highcharts";
import Highcharts from "highcharts";
import { Options } from "highcharts";
import { SelectItem } from "primeng/api";
import { GraficoDashboard } from "src/app/shared/models/grafico-dashboard.model";
import { Util } from "src/app/shared/services/util.service";

@Component({
  selector: "app-card-chart-dashboard",
  templateUrl: "card-chart-dashboard.component.html",
  styleUrls: ["card-chart-dashboard.component.scss"],
  host: {
    "(window:resize)": "onResize($event)",
  },
})
export class CardChartDashboardComponent implements OnInit {
  @Input() data: GraficoDashboard;
  @Input() opcoesFiltro: any[];
  @Input() type: string;
  @Input() isFilter: boolean;
  @Input() exibirExpandido: boolean = true;
  @Input() isGraficoExpandido = false;
  @Output() onSelectItemFiltro: EventEmitter<any> = new EventEmitter<any>();
  @Output() atualizarGraficos = new EventEmitter();
  @Output() valorId = new EventEmitter();
  @Output() expandir = new EventEmitter();
  chart: Chart;
  series: any[];
  totalQueixas: number = 0;
  opcoesFiltrosGrafico: SelectItem[];
  model: any = null;
  x: any;

  initialHeight = window.innerHeight;
  initialWidth = window.innerWidth;

  constructor(public rendered2: Renderer2) {}

  ngOnInit() {
    this.calcularTotalQueixas();

    if (this.opcoesFiltro != null) {
      this.opcoesFiltrosGrafico = Util.jsonArrayToSelectItem(this.opcoesFiltro, "nome");
    }

    this.series = this.data.dados.map((valor) => {
      const serie: any = {};
      serie.dataLabels = {
        style: {
          fontFamily: "Roboto",
          fontSize: "14px",
        },
      };
      serie.b = serie.qtd;
      serie.name = valor.name;
      serie.qtd = valor.qtd;
      serie.hint = valor.hint;
      serie.color = valor.color;
      serie.y = this.type == "COLUMN" ? valor.qtd : valor.y;
      serie.idNivelResultado = valor.idNivelResultado;
      serie.id = valor.id;
      serie.idStr = valor.idStr;
      return serie;
    });
    this.montarGrafico();
  }

  montarGrafico() {
    if (this.type == "PIE") {
      this.getConfiguracaoChartPie();
    } else if (this.type == "COLUMN") {
      this.getConfiguracaoChartColumn();
    } else {
      this.getConfiguracaoChartbar();
    }
  }

  getConfiguracaoChartPie(): any {
    const optionsExpandido: Options = {
      chart: {
        marginRight: 0,
      },
      title: {
        align: "center",
        floating: true,
        verticalAlign: "middle",
        widthAdjust: 0,
        x: 6,
        y: 15,
        style: {
          fontSize: "28px",
        },
      },
      legend: {
        floating: true,
      },
      plotOptions: {
        pie: {
          size: null,
        },
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: (ev) => {
                this.getItemSelecionado(ev.point);
              },
            },
          },
        },
      },
    };
    this.chart = new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        marginRight: 120,
      },
      credits: {
        enabled: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                enabled: true,
              },
            },
          },
          {
            condition: {
              minWidth: 501,
            },
            chartOptions: this.isGraficoExpandido ? optionsExpandido : null,
          },
        ],
      },
      title: {
        text: String(this.data.qtTotal),
        verticalAlign: "middle",
        floating: true,
        x: -54,
      },
      tooltip: {
        formatter: function () {
          return `<b> ${(this.point as any).qtd} </b> (${this.y}%) `;
        },
      },
      plotOptions: {
        series: {
          stacking: "percent",
          cursor: "pointer",
          point: {
            events: {
              click: (ev) => {
                this.getItemSelecionado(ev.point);
              },
            },
          },
        },
        pie: {
          // center: [0, 90],
          size: 150,
          showInLegend: true,
          allowPointSelect: true,
          // colors: Highcharts.getOptions().colors.map((c, i) =>
          //   // Start out with a darkened base color (negative brighten), and end
          //   // up with a much brighter color
          //   Highcharts.color(Highcharts.getOptions().colors[0])
          //     .brighten((i - 3) / 7)
          //     .get()
          // ),
          dataLabels: {
            enabled: true,
            // defer: true,
            distance: -25,
            format: "<b>{point.y}</b>%",
            // color: "black",
            // connectorShape: "straight",
            filter: {
              property: "percentage",
              operator: ">",
              value: 10,
            },
          },
          // startAngle: 0,
          // endAngle: 360,
          states: {
            hover: {
              brightness: -0.2,
            },
          },
        },
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        width: "30%",
      },
      series: [
        {
          type: "pie",
          innerSize: "50%",
          data: this.series,
        },
      ],
    });
  }

  getConfiguracaoChartbar(): any {
    this.chart = new Chart({
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: this.data.dados.map((dado) => dado.name),
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
          align: "high",
        },
        labels: {
          overflow: "justify",
        },
        endOnTick: true,
        max: 100,
      },
      tooltip: {
        formatter: function () {
          const p = this.point as any;
          const percentage = this.point.y.toFixed(2) + "%";
          if (p.hint) {
            return `<b> ${percentage} ${p.qtd ? ` - ${p.qtd}` : ""}</b> <br/> <p> ${p.hint} </p>`;
          }
          return `<span>${this.point.name}</span>: <b>${percentage}</b><br/> <b>${p.options.qtd}</b><br/>`;
        },
      },

      plotOptions: {
        series: {
          shadow: false,
          borderWidth: 0,
          animation: true,
          dataLabels: [
            {
              enabled: true,
              formatter: function () {
                return this.point.y + "%";
              },
            },
          ],
          cursor: "pointer",
          point: {
            events: {
              click: (ev) => {
                this.getItemSelecionado(ev.point);
              },
            },
          },
        },
        column: {
          stacking: "percent",
        },
        bar: {
          allowPointSelect: true,
          dataLabels: {
            enabled: false,
            color: "black",
          },
          states: {
            hover: {
              brightness: -0.1,
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
    });
    this.chart.addSeries(
      {
        showInLegend: false,
        type: "bar",
        data: this.series,
      },
      true,
      true
    );
  }

  getConfiguracaoChartColumn(): any {
    this.chart = new Chart({
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: this.data.dados.map((dado) => dado.name),
        title: {
          text: null,
        },
      },
      yAxis: {
        visible: false,
        min: 0,
        title: {
          text: "",
          align: "high",
        },
        labels: {
          overflow: "justify",
        },
        gridLineWidth: 0,
      },
      tooltip: {
        formatter: function () {
          return `<span>${this.point.name}</span>: <b>${this.point.y}</b>`;
        },
      },
      plotOptions: {
        series: {
          shadow: false,
          borderWidth: 0,
          animation: true,
          dataLabels: [
            {
              enabled: true,
              crop: false,
              overflow: "allow",
            },
          ],
          cursor: "pointer",
          point: {
            events: {
              click: (ev) => {
                this.getItemSelecionado(ev.point);
              },
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
    });
    this.chart.addSeries(
      {
        showInLegend: false,
        type: "column",
        data: this.series,
      },
      true,
      true
    );
  }

  abrirFiltro(op: any, event: any): void {
    op.toggle(event);
  }

  onSelectItem(event, op): void {
    this.onSelectItemFiltro.emit(this.model);
  }

  getItemSelecionado(point): any {
    this.atualizarGraficos.emit({
      tituloGrafico: point.nome,
      idNivelResultado: point.idNivelResultado,
    });
    this.valorId.emit(point);
  }

  limparFiltro(): void {
    this.model = null;
    this.onSelectItemFiltro.emit(this.model);
  }

  onResize() {
    const porcentagemCorte = 20;
    const porcentagemHeight = (window.innerHeight / this.initialHeight) * 100;
    const porcentagemWidth = (window.innerWidth / this.initialWidth) * 100;
    const diferencaHeight = 100 - Math.abs(porcentagemHeight);
    const diferencaWidth = 100 - Math.abs(porcentagemWidth);
    if (diferencaHeight >= porcentagemCorte || diferencaWidth >= porcentagemCorte) {
      this.initialHeight = window.innerHeight;
      this.initialWidth = window.innerWidth;
      this.chart.destroy();
      setTimeout(() => {
        this.montarGrafico();
      }, 100);
    }
  }

  calcularTotalQueixas() {
    if (this.data && this.data.flQueixas) {
      this.totalQueixas = this.data.dados.reduce((prev, cur) => prev + cur.qtd, 0);
    }
  }
}
