<div class="card-wrapper">
  <div class="card-container">
    @if (hierarquia) {
      <div class="item --nivel3">
        <h4 class="titulo">{{ hierarquia.dsHierarquia }}</h4>
        <div class="score-circle" [ngClass]="scoreClass(hierarquia.qtScoreTotal)">
          {{ hierarquia.qtScoreTotal }}
          <span> score </span>
        </div>
        @if (hierarquia.hierarquiasFilhas?.length) {
          <button class="sme-primary" pButton translate (click)="detalhesScoreClick.emit(hierarquia)">
            sinalizador.compilado.detalhesScore
          </button>
        }
      </div>
      @if (!hierarquia.hierarquiasFilhas?.length) {
        <app-compilado-graficos variant="inline" [hierarquia]="hierarquiaNivel3" [filtros]="filtros" />
      }
    }
    @for (item of hierarquiaFilhas; track $index; let last = $last) {
      <div class="item">
        <app-compilado-card-item [hierarquiaNivel4]="item" />
      </div>
      @if (!last) {
        <em class="pi pi-arrows-h"></em>
      }
    }
  </div>
</div>
