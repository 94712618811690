import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UsuarioHistoricoAusenciaService } from "src/app/shared/api-services/usuario-historico-ausencia.service";
import { ResponseHistoricoFeriasTO } from "src/app/shared/tos/response/response-historico-ferias.to";
import { Column } from "src/app/shared/models/column.model";
import moment from "moment";
import { NotificationType } from "src/app/shared/enums/notification-type.enum";
import { NotificationService } from "src/app/shared/services/notification.service";
import { DatePipe } from "@angular/common";
import { ResponseUsuarioSimplesTO } from "src/app/shared/tos/response/response-usuario-simple.to";

@Component({
  selector: "app-historico-ferias",
  templateUrl: "./historico-ferias.component.html",
  styleUrls: ["./historico-ferias.component.scss"],
  providers: [DatePipe],
})
export class HistoricoFeriasComponent implements OnInit {
  @Output() editar = new EventEmitter();

  usuario: ResponseUsuarioSimplesTO;
  showDialog: boolean;
  historico: ResponseHistoricoFeriasTO[] = [];
  colunas: Column[];
  loading = false;

  podeEditar = false;

  constructor(
    private avisoFeriasService: UsuarioHistoricoAusenciaService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.configurarTabela();
  }

  abrirDialog(usuario: ResponseUsuarioSimplesTO) {
    this.showDialog = true;
    this.usuario = usuario;
    this.buscarHistoricoUsuario(usuario);
  }

  configurarTabela() {
    const convertDate = (date: Date) => date && this.datePipe.transform(date, "shortDate");
    const convertDateTime = (date: Date) => date && this.datePipe.transform(date, "short");
    this.colunas = [
      {
        header: this.translateService.instant("avisoFerias.dtInicial"),
        field: "dtInicioFerias",
        valueDisplayFn: convertDate,
        textAlign: "center",
        textHeaderAlign: "center",
      },
      {
        header: this.translateService.instant("avisoFerias.dtFinal"),
        field: "dtFimFeriasCadastrada",
        valueDisplayFn: convertDate,
        textAlign: "center",
        textHeaderAlign: "center",
      },
      {
        header: this.translateService.instant("avisoFerias.dtFinalAjustada"),
        field: "dtFimFerias",
        valueDisplayFn: convertDate,
        textAlign: "center",
        textHeaderAlign: "center",
      },
      {
        header: this.translateService.instant("avisoFerias.dtInclusao"),
        field: "dtInclusao",
        valueDisplayFn: convertDateTime,
        textAlign: "center",
        textHeaderAlign: "center",
      },
      {
        header: this.translateService.instant("avisoFerias.justificativa"),
        field: "tpJustificativa",
        valueDisplayFn: (value) => this.translateService.instant("avisoFerias." + value),
      },
      { header: this.translateService.instant("avisoFerias.observacao"), field: "dsObservacaoAusencia" },
    ];
  }

  buscarHistoricoUsuario(usuario: ResponseUsuarioSimplesTO) {
    if (usuario && usuario.id) {
      this.loading = true;
      this.avisoFeriasService.getHistoricoByIdUsuario(usuario.id).subscribe(
        (r) => {
          this.loading = false;
          this.historico = r;
        },
        (err) => {
          this.loading = false;
          throw err;
        }
      );
    } else {
      this.historico = [];
      this.podeEditar = false;
    }
  }

  editarAviso(aviso: ResponseHistoricoFeriasTO) {
    this.showDialog = false;
    this.editar.emit(aviso);
  }

  handleDeletar(aviso: ResponseHistoricoFeriasTO) {
    this.notificationService.notify(
      this.translateService.instant("conteudoConfirmaExclusao"),
      NotificationType.WARNING,
      undefined,
      this.deletarAviso.bind(this, aviso),
      () => console.log("Não excluir")
    );
  }

  deletarAviso(aviso: ResponseHistoricoFeriasTO) {
    this.loading = true;
    this.avisoFeriasService.deletarAvisoAusencia(aviso.id).subscribe(
      (r) => {
        this.loading = false;
        this.buscarHistoricoUsuario(this.usuario);
      },
      (err) => {
        this.loading = false;
        throw err;
      }
    );
  }

  verificarPodeEditarExcluir(aviso: ResponseHistoricoFeriasTO) {
    const today = moment();
    this.podeEditar = today.isBefore(aviso.dtFimFerias, "day");
    // this.podeExcluir = today.isBefore(aviso.dtInicioFerias, 'day') && today.isBefore(aviso.dtFimFerias, 'day');
  }
}
