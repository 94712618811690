// Scores também estão no Variables.scss
// Se alterar aqui precisa alterar lá também
export enum ScoreColors {
  Score0 = "#9ADC31",
  Score1 = "#E7F497",
  Score2 = "#E9D525",
  Score3 = "#E99B25",
  Score4 = "#FF4343",
  Score5 = "#FF4343",
}
