import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { getScoreClass } from "src/app/shared/services/score-class";
import { SharedModule } from "src/app/shared/shared.module";
import { ResponseSinalizadorProntidaoCompiladoHierarquia } from "src/app/shared/tos/response/response-sinalizador-prontidao-compilado-hierarquia.to";

@Component({
  selector: "app-compilado-navigation",
  standalone: true,
  imports: [NgClass, SharedModule],
  templateUrl: "./compilado-navigation.component.html",
  styleUrl: "./compilado-navigation.component.scss",
})
export class CompiladoNavigationComponent {
  @Output() detalhesClick = new EventEmitter();
  @Output() hierarquiaNivel2Change = new EventEmitter();
  @Input() hierarquias: ResponseSinalizadorProntidaoCompiladoHierarquia[];
  @Input() hierarquiaNivel2: ResponseSinalizadorProntidaoCompiladoHierarquia;

  onHierarquiaChange(hierarquia: ResponseSinalizadorProntidaoCompiladoHierarquia) {
    this.hierarquiaNivel2 = hierarquia;
    this.hierarquiaNivel2Change.emit(this.hierarquiaNivel2);
  }

  scoreClass(score) {
    return getScoreClass(score);
  }
}
