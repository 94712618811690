import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { CompiladoCardItemComponent } from "../compilado-card-item/compilado-card-item.component";
import { ResponseSinalizadorProntidaoCompiladoHierarquia } from "src/app/shared/tos/response/response-sinalizador-prontidao-compilado-hierarquia.to";
import { SharedModule } from "src/app/shared/shared.module";
import { getScoreClass } from "src/app/shared/services/score-class";
import { NgClass } from "@angular/common";
import { ParamsSinalizadorProntidao } from "src/app/shared/tos/request/params-sinalizador-prontidao";
import { CompiladoGraficosComponent } from "../compilado-graficos/compilado-graficos.component";

@Component({
  selector: "app-compilado-card",
  standalone: true,
  imports: [CompiladoCardItemComponent, CompiladoGraficosComponent, SharedModule, NgClass],
  templateUrl: "./compilado-card.component.html",
  styleUrl: "./compilado-card.component.scss",
})
export class CompiladoCardComponent implements OnChanges {
  @Output() detalhesScoreClick = new EventEmitter();
  @Input() hierarquiaNivel3: ResponseSinalizadorProntidaoCompiladoHierarquia;
  @Input() filtros: ParamsSinalizadorProntidao;
  hierarquia: ResponseSinalizadorProntidaoCompiladoHierarquia;
  hierarquiaFilhas: ResponseSinalizadorProntidaoCompiladoHierarquia[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hierarquiaNivel3?.currentValue) {
      this.selecionarHierarquia(this.hierarquiaNivel3);
    }
  }

  selecionarHierarquia(hierarquiaSuperior: ResponseSinalizadorProntidaoCompiladoHierarquia) {
    const filhasPossuemHierarquia = hierarquiaSuperior.hierarquiasFilhas?.some(
      (filha) => filha.hierarquiasFilhas?.length,
    );
    this.hierarquiaFilhas = hierarquiaSuperior.hierarquiasFilhas;

    if (!filhasPossuemHierarquia) {
      this.hierarquia = null;
      this.hierarquiaFilhas = [hierarquiaSuperior];
    } else {
      this.hierarquia = hierarquiaSuperior;
    }
  }

  scoreClass(score) {
    return getScoreClass(score, true);
  }
}
