import { ParamsSinalizadorProntidaoUsuariosHierarquia } from "./../../../../shared/tos/request/params-sinalizador-prontidao-usuarios-hierarquia";
import { Component, OnInit, ViewChild } from "@angular/core";
import { SinalizadorProntidaoService } from "src/app/shared/api-services/sinalizador-prontidao.service";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { RoleService } from "src/app/shared/services/role.service";
import { ResponseSinalizadorProntidaoMediaTO } from "src/app/shared/tos/response/response-sinalizador-prontidao-media.to";
import { FiltrosSinalizadorProntidaoComponent } from "./filtros-sinalizador-prontidao/filtros-sinalizador-prontidao.component";
import { LinhaTabelaSinalizadorProntidao } from "./tabela-sinalizador-prontidao/linha-tabela-sinalizador-prontidao";
import { ParamsSinalizadorProntidao } from "src/app/shared/tos/request/params-sinalizador-prontidao";
import { ResponseSinalizadorProntidao } from "src/app/shared/tos/response/response-sinalizador-prontidao.to";
import { Observable } from "rxjs";
import { SelectItem } from "primeng/api";
import { ContratoService } from "src/app/shared/api-services/contrato.service";
import { Util } from "src/app/shared/services/util.service";
import { TranslateService } from "@ngx-translate/core";
import { PeriodoEnum } from "src/app/shared/enums/periodo.enum";
import { HierarquiaService } from "src/app/shared/api-services/hierarquia.service";
import { ResponseSinalizadorProntidaoConfiguracaoTO } from "src/app/shared/tos/response/response-sinalizador-prontidao-configuracao.to";
import { ResponseSinalizadorProntidaoCompiladoHierarquia } from "src/app/shared/tos/response/response-sinalizador-prontidao-compilado-hierarquia.to";

@Component({
  selector: "app-sinalizador-prontidao",
  templateUrl: "./sinalizador-prontidao.component.html",
  styleUrls: ["./sinalizador-prontidao.component.scss"],
})
export class SinalizadorProntidaoComponent implements OnInit {
  @ViewChild(FiltrosSinalizadorProntidaoComponent) filtrosProntidao: FiltrosSinalizadorProntidaoComponent;

  loadingHierarquia = false;
  loadingUsuario = false;
  loadingCompilado = false;
  isIdContratoRequired = false;
  podeVisualizarValores = false;
  exibirValores = false;
  showAbaUsuarios = true;
  podeExpandirHierarquias = true;

  customErroTabela: string;
  filtros: ParamsSinalizadorProntidao = {
    cdPeriodo: PeriodoEnum.DIAS_30,
    flIncluirFilhas: true,
    idHierarquia: this.hierarquiaService.getHierarquiasUsuario(),
    flApenasUsuarioAtivos: false,
    idCargo: [],
    idTurno: [],
  } as any;
  media: ResponseSinalizadorProntidaoMediaTO;
  configuracao: ResponseSinalizadorProntidaoConfiguracaoTO;
  dadosTabelaHierarquia: LinhaTabelaSinalizadorProntidao[];
  dadosTabelaUsuario: LinhaTabelaSinalizadorProntidao[];
  dadosCompilado: ResponseSinalizadorProntidaoCompiladoHierarquia;
  listaContratos: SelectItem[] = [];

  idContrato: number;
  idxTabAtivo: number = 0;
  dynamicTableRows: number = 30;

  constructor(
    private sinalizadorProntidaoService: SinalizadorProntidaoService,
    private roleService: RoleService,
    private contratoService: ContratoService,
    private translateService: TranslateService,
    private hierarquiaService: HierarquiaService,
  ) {}

  ngOnInit() {
    this.isIdContratoRequired =
      this.roleService.isRole(Permissao.ADMINISTRADOR) ||
      this.roleService.isRole(Permissao.GESTOR_SME) ||
      this.roleService.isRole(Permissao.GESTOR_SME_MEDICAO);
    this.podeVisualizarValores =
      this.roleService.isRole(Permissao.ADMINISTRADOR) ||
      this.roleService.isRole(Permissao.GESTOR_SME) ||
      this.roleService.isRole(Permissao.GESTOR_SME_MEDICAO);
    this.podeExpandirHierarquias = !this.roleService.isRole(Permissao.GESTOR);
    this.showAbaUsuarios = !this.roleService.isRole(Permissao.GESTOR);
    this.idxTabAtivo =
      this.showAbaUsuarios && this.hierarquiaService.getHierarquiasUsuario().length < 2 ? 1 : 0;
    this.handleOnTabChange({ index: this.idxTabAtivo });
    this.getContratos();
  }

  getContratos() {
    if (this.isIdContratoRequired) {
      this.contratoService.getListaContratos().subscribe((contratos) => {
        this.listaContratos = Util.jsonArrayToSelectItem(contratos, "dsHierarquia", null, "idContrato");
        if (this.listaContratos.length === 1) {
          this.idContrato = this.listaContratos[0].value;
          this.handleOnContratoChange(this.idContrato);
        }
      });
    }
  }

  openFiltros() {
    this.filtrosProntidao.open();
  }

  isFiltrosValidos() {
    this.customErroTabela = "";
    if (this.isIdContratoRequired && !this.filtros?.idContrato) {
      this.customErroTabela = this.translateService.instant("sinalizador.erroSelecionarContrato");
      return false;
    }
    return true;
  }

  getSinalizador(
    endpoint: (filtros: ParamsSinalizadorProntidao) => Observable<ResponseSinalizadorProntidao>,
    variavelArmazenar: string,
    variavelLoading: string,
  ) {
    if (this.isFiltrosValidos()) {
      this[variavelLoading] = true;
      this[variavelArmazenar] = [];
      endpoint(this.filtros).subscribe(
        (response) => {
          const dados = response.hierarquias || response.usuarios;
          this[variavelLoading] = false;
          this.media = response.mediaGeral;
          this.configuracao = response.config;
          this[variavelArmazenar] = dados.map((hierarquia) =>
            new LinhaTabelaSinalizadorProntidao().fromResponse(hierarquia),
          );
        },
        (err) => {
          this[variavelLoading] = false;
          throw err;
        },
      );
    }
  }

  getDadosCompilado() {
    if (this.isFiltrosValidos()) {
      this.loadingCompilado = true;
      this.dadosCompilado = null;
      if (!this.filtros.idHierarquia?.[0]) {
        this.filtros.idHierarquia = this.hierarquiaService.getHierarquiaFromToken() as any;
      }
      const idHierarquia = this.filtros.idHierarquia?.[0] || (this.filtros.idHierarquia as any);
      this.sinalizadorProntidaoService.getSinalizadorProntidaoCompilado(idHierarquia, this.filtros).subscribe(
        (response) => {
          this.loadingCompilado = false;
          this.media = response.mediaGeral;
          this.configuracao = response.config;
          this.dadosCompilado = response.hierarquia;
        },
        (err) => {
          this.loadingCompilado = false;
          throw err;
        },
      );
    }
  }

  getUsuariosByHierarquia(linha: LinhaTabelaSinalizadorProntidao) {
    if (linha.usuariosCarregados) {
      return;
    }
    const filtros = new ParamsSinalizadorProntidaoUsuariosHierarquia().fromFiltrosHierarquia(
      this.filtros,
      this.media,
    );
    linha.isLoading = true;
    this.sinalizadorProntidaoService
      .getUsuariosSinalizadorProntidaoByHierarquia(linha.idHierarquia, filtros)
      .subscribe(
        (response) => {
          linha.isLoading = false;
          linha.usuariosCarregados = true;
          const novasLinhas = response.map((usuario) =>
            new LinhaTabelaSinalizadorProntidao().fromUsuarioByHierarquiaResopnse(usuario),
          );
          this.dadosTabelaHierarquia = [...this.dadosTabelaHierarquia, ...novasLinhas];
          this.dynamicTableRows += novasLinhas.length + 1;
        },
        (err) => {
          linha.isLoading = false;
          throw err;
        },
      );
  }

  handleOnContratoChange(idContrato: number) {
    this.filtros.idContrato = idContrato;
    this.media = null;
    this.handleGetSinalizador(this.idxTabAtivo);
  }

  handleOnTabChange({ index }) {
    this.idxTabAtivo = index;
    this.handleGetSinalizador(index);
  }

  isCompiladoActive(index) {
    return index == 2;
  }

  handleGetSinalizador(index: number) {
    // compilado
    if (this.isCompiladoActive(index)) {
      this.getDadosCompilado();
      return;
    }
    const map = {
      0: {
        endpoint: this.sinalizadorProntidaoService.getHierarquiaSinalizadorProntidao.bind(
          this.sinalizadorProntidaoService,
        ),
        variavelArmazenar: "dadosTabelaHierarquia",
        variavelLoading: "loadingHierarquia",
      },
      1: {
        endpoint: this.sinalizadorProntidaoService.getUsuariosSinalizadorProntidao.bind(
          this.sinalizadorProntidaoService,
        ),
        variavelArmazenar: "dadosTabelaUsuario",
        variavelLoading: "loadingUsuario",
      },
    };
    if (map[index]) {
      this.idxTabAtivo = index;
      this.getSinalizador(map[index].endpoint, map[index].variavelArmazenar, map[index].variavelLoading);
    }
  }

  onFiltrar(filtros: ParamsSinalizadorProntidao) {
    this.filtros = filtros;
    this.filtros.idContrato = this.idContrato;
    this.handleGetSinalizador(this.idxTabAtivo);
  }

  async acessarPrimeiroElemento() {
    return new Promise(async (res) => {
      if (this.isIdContratoRequired) {
        const hasLista = await Util.waitUntil(() => this.listaContratos?.length > 0);
        if (hasLista) {
          this.idContrato = this.listaContratos[0].value;
        } else {
          res(false);
        }
      }
      this.handleOnContratoChange(this.idContrato);
      await Util.waitUntil(() => !this.loadingHierarquia && !this.loadingUsuario);
      res(true);
    });
  }

  showTable() {
    return this.isIdContratoRequired ? !!this.idContrato : true;
  }
}
