<!-- FILTRO DADOS DASHBOARD-->
<p-dialog
  id="idDialogFiltrosDashboard"
  [modal]="true"
  [style]="{ width: '765px', 'min-height': '650px' }"
  maskStyleClass="overlayFiltros"
  [showHeader]="true"
  [header]="'dashboard.tituloFiltro' | translate"
  [(visible)]="showDialogFiltroGrafico"
  [closable]="true"
>
  <form (submit)="handleSubmit()">
    <input type="hidden" />
    @if (isFiltrosIndicadorFadiga) {
    <div class="p-grid">
      <div class="p-col-6 p-md-3">
        <label for="dtAnalise" class="label-input" translate>dashboard.filtroGraficoDtAnalise</label>
        <p-calendar
          appendTo="body"
          class="calendar"
          [monthNavigator]="true"
          dateFormat="dd/mm/yy"
          [locale]="'calendar' | translate"
          [yearNavigator]="true"
          [yearRange]="yearRange"
          [showTime]="false"
          [(ngModel)]="dadosFiltroGraficoDashboard['dtAnalise']"
          name="dadosFiltroGraficoDashboard['dtAnalise']"
          [minDate]="minimoDiaMes"
          required
        ></p-calendar>
      </div>
      <div class="p-col-6 p-md-3">
        <label for="scoreFadiga" class="label-input" translate>dashboard.scoreFadiga</label>
        <app-dropdown
          name="dadosFiltroGraficoDashboard.scoreFadiga"
          [options]="listaScoreFadiga"
          [(ngModel)]="dadosFiltroGraficoDashboard.scoreFadiga"
        >
        </app-dropdown>
      </div>
    </div>
    } @if (!isFiltrosIndicadorFadiga) {
    <div class="p-grid">
      <div class="p-col-6 p-md-3">
        <label for="horaInicial" class="label-input" translate>dashboard.filtroGraficoDtInicial</label>
        <p-calendar
          appendTo="body"
          class="calendar"
          [monthNavigator]="true"
          dateFormat="dd/mm/yy"
          [locale]="'calendar' | translate"
          [yearNavigator]="true"
          [yearRange]="yearRange"
          [showTime]="false"
          [(ngModel)]="dadosFiltroGraficoDashboard['dtInicio']"
          name="dadosFiltroGraficoDashboard['dtInicio']"
          [minDate]="minimoDiaMes"
          required
        ></p-calendar>
      </div>
      <div class="p-col-6 p-md-3">
        <label class="label-input" translate>dashboard.filtroGraficoHoraInicial</label>
        <p-calendar
          appendTo="body"
          class="calendar"
          [locale]="'calendar' | translate"
          [timeOnly]="true"
          [(ngModel)]="dadosFiltroGraficoDashboard['horaInicio']"
          name="dadosFiltroGraficoDashboard['horaInicio']"
        >
        </p-calendar>
      </div>
      <div class="p-col-6 p-md-3">
        <label class="label-input" translate>dashboard.filtroGraficoDtFinal</label>
        <p-calendar
          appendTo="body"
          class="calendar"
          [monthNavigator]="true"
          dateFormat="dd/mm/yy"
          [locale]="'calendar' | translate"
          [yearNavigator]="true"
          [yearRange]="yearRange"
          [showTime]="false"
          [(ngModel)]="dadosFiltroGraficoDashboard['dtFim']"
          name="dadosFiltroGraficoDashboard['dtFim']"
          required
        >
        </p-calendar>
      </div>
      <div class="p-col-6 p-md-3">
        <label class="label-input" translate>dashboard.filtroGraficoHoraFinal</label>
        <p-calendar
          appendTo="body"
          class="calendar"
          [locale]="'calendar' | translate"
          [timeOnly]="true"
          [(ngModel)]="dadosFiltroGraficoDashboard['horaFim']"
          name="dadosFiltroGraficoDashboard['horaFim']"
        >
        </p-calendar>
      </div>
    </div>
    } @if (!isFiltrosIndicadorFadiga) {
    <div class="p-grid">
      @if (exibirFiltroMatricula) {
      <div class="p-col-12 p-md-6">
        <label for="matricula" class="label-input" translate>gerarRelatorio.matricula</label>
        <input
          id="matricula"
          name="matricula"
          type="text"
          pInputText
          [(ngModel)]="dadosFiltroGraficoDashboard['cdMatriculaUsuario']"
        />
      </div>
      }
      <div class="p-col-12 p-md-6" [class.p-md-12]="!exibirFiltroMatricula">
        <label class="label-input" translate>gerarRelatorio.formacaoBancoDados</label>
        <app-dropdown
          name="dadosFiltroGraficoDashboard.situacaoImpulsividadeEstimada"
          [options]="listaFormacaoBancoDados"
          [(ngModel)]="dadosFiltroGraficoDashboard.situacaoImpulsividadeEstimada"
        />
      </div>
      @if (criteriosInsercaoPreDefinidos) {
      <div class="p-col-12">
        <label class="label-input" translate>dashboard.formacao</label>
        <div style="display: flex">
          <app-dropdown
            name="dadosFiltroGraficoDashboard.dsFormacao"
            class="dropdown"
            [(ngModel)]="dadosFiltroGraficoDashboard.dsFormacao"
            [options]="criteriosInsercaoPreDefinidos"
          >
          </app-dropdown>
        </div>
      </div>
      }
    </div>
    }

    <div class="p-grid">
      <div class="p-col-12">
        <app-turno-field
          [(ngModel)]="dadosFiltroGraficoDashboard.idTurno"
          name="idTurno"
          [fetchTurnos]="false"
          [listaTurnos]="listaTurnos"
        />
      </div>

      <div class="p-col-12">
        <app-cargo-field
          [(ngModel)]="dadosFiltroGraficoDashboard.idCargo"
          name="idCargo"
          [fetchCargos]="false"
          [listaCargos]="listaCargos"
        />
      </div>

      <div class="p-col-12">
        <app-hierarquia-field
          scrollHeight="150px"
          [(ngModel)]="dadosFiltroGraficoDashboard.idHierarquia"
          [fetchHierarquias]="false"
          [hierarquiasTreeNode]="hierarquias"
          name="hierarquiaSelecionadaFiltroDashboard"
        />
        @if (!dadosFiltroGraficoDashboard.idHierarquia?.length) {
        <small class="p-error" translate> erros.required </small>
        }
      </div>

      <div class="p-col-12 p-md-3">
        <label>
          <p-checkbox
            [label]="'dashboard.filtroIncluirFilhas' | translate"
            [binary]="true"
            name="dadosFiltroGraficoDashboard.flIncluirFilhas"
            [(ngModel)]="dadosFiltroGraficoDashboard.flIncluirFilhas"
          >
          </p-checkbox>
        </label>
      </div>
      @if (!isFiltrosIndicadorFadiga) {
      <div class="p-col-12 p-md-5">
        <label>
          <p-checkbox
            [label]="'dashboard.filtroIncluirAvisoAusencia' | translate"
            [binary]="true"
            name="dadosFiltroGraficoDashboard.flIncluirAvisoAusencia"
            [(ngModel)]="dadosFiltroGraficoDashboard.flIncluirAvisoAusencia"
          >
          </p-checkbox>
        </label>
      </div>
      } @if (!isFiltrosIndicadorFadiga) {
      <div class="p-col-12">
        <label>
          <p-checkbox
            (onChange)="onChangeApenasUsuariosAtivos()"
            [label]="'dashboard.filtroApenasUsuarioAtivos' | translate"
            [binary]="true"
            name="dadosFiltroGraficoDashboard.flApenasUsuarioAtivos"
            [(ngModel)]="checkboxApenasUsuariosAtivos"
          >
          </p-checkbox>
        </label>
      </div>
      }

      <div class="p-col-12">
        <div class="p-grid">
          <div class="p-col-6">
            <button type="button" class="botao-cancelar" (click)="fecharDialogoFiltroGrafico()">
              <span translate>dashboard.cancelar</span>
            </button>
          </div>
          <div class="p-col-6">
            <button
              pButton
              [disabled]="!isFormValid()"
              type="submit"
              class="botao-confirmar"
              [disabled]="loading"
              [loading]="loading"
            >
              <span translate>dashboard.confirmar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
