import { Component, ChangeDetectionStrategy } from "@angular/core";
import { ScoreColors } from "src/app/shared/enums/score-colors.enum";

@Component({
  selector: "app-legenda-sinalizador-prontidao",
  templateUrl: "./legenda-sinalizador-prontidao.component.html",
  styleUrls: ["./legenda-sinalizador-prontidao.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendaSinalizadorProntidaoComponent {
  flExibirLegenda: boolean;
  fatoresRisco = [
    ScoreColors.Score0,
    ScoreColors.Score1,
    ScoreColors.Score2,
    ScoreColors.Score3,
    ScoreColors.Score4,
  ];

  showLegenda() {
    this.flExibirLegenda = true;
  }
}
