<div class="nav-container">
  @for (hierarquia of hierarquias; track hierarquia.idHierarquia) {
    <button
      class="nav-item"
      [ngClass]="{ active: hierarquiaNivel2.idHierarquia == hierarquia.idHierarquia }"
      (click)="onHierarquiaChange(hierarquia)"
    >
      <p class="titulo">{{ hierarquia.dsHierarquia }}</p>
      <div class="score-badge" [ngClass]="scoreClass(hierarquia.qtScoreTotal)">
        Score {{ hierarquia.qtScoreTotal }}
      </div>
      <button class="detalhes-texto sme-text" (click)="detalhesClick.emit(hierarquia)">
        <span translate> sinalizador.compilado.detalhesScore </span>
        <em class="pi pi-chevron-right"></em>
      </button>
    </button>
  }
</div>
