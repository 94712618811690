@if (loading) {
  <app-loader [loading]="loading" type="normal" />
} @else if (!loading && hierarquiaNivel1) {
  <app-compilado-header [dsHierarquia]="hierarquiaNivel1.dsHierarquia" />

  @if (!hierarquiaNivel1?.hierarquiasFilhas?.length) {
    <ng-container *ngTemplateOutlet="graficosTemplate; context: { $implicit: hierarquiaNivel1 }" />
  } @else {
    <app-compilado-navigation
      [hierarquias]="hierarquiaNivel1.hierarquiasFilhas"
      [(hierarquiaNivel2)]="hierarquiaNivel2"
      (detalhesClick)="abrirDetalhesScore($event)"
    />

    @if (!hierarquiaNivel2?.hierarquiasFilhas?.length) {
      <ng-container *ngTemplateOutlet="graficosTemplate; context: { $implicit: hierarquiaNivel2 }" />
    } @else {
      @for (item of hierarquiaNivel2?.hierarquiasFilhas; track $index) {
        <app-compilado-card
          [hierarquiaNivel3]="item"
          [filtros]="filtros"
          (detalhesScoreClick)="abrirDetalhesScore($event)"
        />
      }
      <p-dialog
        [(visible)]="showPopupGraficos"
        modal="true"
        [style]="{ width: '90%', 'max-width': '1200px' }"
      >
        @if (showPopupGraficos) {
          <ng-container
            *ngTemplateOutlet="
              graficosTemplate;
              context: { $implicit: hierarquiaDetalhes, showDsHierarquia: true }
            "
          />
        }
      </p-dialog>
    }
  }
}

<ng-template #graficosTemplate let-hierarquia let-showDsHierarquia="showDsHierarquia">
  <app-compilado-graficos
    [hierarquia]="hierarquia"
    [filtros]="filtros"
    [showDsHierarquia]="showDsHierarquia"
  />
</ng-template>
