import { Component, Input } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";

@Component({
  selector: "app-compilado-header",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./compilado-header.component.html",
  styleUrl: "./compilado-header.component.scss",
})
export class CompiladoHeaderComponent {
  @Input() dsHierarquia: string;
}
