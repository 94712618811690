<!-- FILTRO DADOS DASHBOARD-->
<p-dialog
  [modal]="true"
  [style]="{ width: '90%', 'max-width': '765px' }"
  [showHeader]="true"
  [header]="'dashboard.tituloFiltro' | translate"
  [(visible)]="showFiltros"
  [closable]="true"
  [draggable]="false"
>
  <form #f="ngForm">
    <div class="p-grid">
      <div class="p-col-12 p-md-5 p-mb-3">
        <label for="cdPeriodo" class="label-input" translate> sinalizador.cdPeriodo </label>
        <div class="periodo-wrapper">
          @for (periodo of periodos; track periodo) {
            <div class="periodo-item">
              <input [id]="periodo" type="radio" name="cdPeriodo" [value]="periodo" ngModel />
              <label [for]="periodo" translate> sinalizador.{{ periodo }} </label>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="p-grid">
      @if (criteriosInsercaoPreDefinidos) {
        <div class="p-col-12 p-md-6 p-mb-3">
          <label for="dsFormacao" class="label-input" translate>usuario.formacao</label>
          <app-dropdown
            class="dropdown"
            name="dsFormacao"
            ngModel
            [options]="criteriosInsercaoPreDefinidos"
          />
        </div>
      }
      <div class="p-col-12 p-mb-3" [ngClass]="{ 'p-md-6': criteriosInsercaoPreDefinidos }">
        <label for="cdMatriculaUsuario" class="label-input" translate> sinalizador.cdMatriculaUsuario</label>
        <input
          id="cdMatriculaUsuario"
          name="cdMatriculaUsuario"
          type="text"
          pInputText
          ngModel
          maxLength="200"
        />
      </div>
      <div class="p-col-12 p-mb-3">
        @if (isCompilado) {
          <app-hierarquia-field
            [selectMultiple]="false"
            [required]="true"
            name="idHierarquia"
            ngModel
            scrollHeight="180px"
          />
        } @else {
          <app-hierarquia-field name="idHierarquia" ngModel scrollHeight="180px" />
        }
        <div class="p-mt-3">
          <p-checkbox
            [label]="'sinalizador.filtroIncluirFilhas' | translate"
            [binary]="true"
            name="flIncluirFilhas"
            ngModel
          />
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-mb-3">
        <app-turno-field name="idTurno" ngModel appendTo="body" />
      </div>

      <div class="p-col-12 p-md-6 p-mb-3">
        <app-cargo-field name="idCargo" ngModel appendTo="body" />
      </div>

      <div class="p-col-12 p-mb-3">
        <p-checkbox
          name="flApenasUsuarioAtivos"
          ngModel
          [label]="'dashboard.filtroApenasUsuarioAtivos' | translate"
          [binary]="true"
        />
      </div>

      <div class="p-col-6">
        <button type="button" class="botao-branco" (click)="fecharFiltros()">
          <span translate>dashboard.cancelar</span>
        </button>
      </div>
      <div class="p-col-6">
        <button type="submit" class="botao-salvar" [disabled]="f.invalid" (click)="handleSubmit(f)">
          <span translate>dashboard.confirmar</span>
        </button>
      </div>
    </div>
  </form>
</p-dialog>
