import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { SortEvent } from "primeng/api";
import { NotificationType } from "src/app/shared/enums/notification-type.enum";
import { NotificationService } from "src/app/shared/services/notification.service";
import { Util } from "src/app/shared/services/util.service";
import { LinhaTabelaSinalizadorProntidao } from "./linha-tabela-sinalizador-prontidao";
import { ResponseSinalizadorProntidaoConfiguracaoTO } from "src/app/shared/tos/response/response-sinalizador-prontidao-configuracao.to";
import { ClienteUtil } from "src/app/shared/util/cliente.util";

@Component({
  selector: "app-tabela-sinalizador-prontidao",
  templateUrl: "./tabela-sinalizador-prontidao.component.html",
  styleUrls: ["./tabela-sinalizador-prontidao.component.scss"],
})
export class TabelaSinalizadorProntidaoComponent implements OnChanges {
  @Output() expandedRowClicked = new EventEmitter<LinhaTabelaSinalizadorProntidao>();
  @Input() tituloColunaNome: string;
  @Input() customErroTabela: string;
  @Input() loading: boolean;
  @Input() showNumeroTabela: boolean;
  @Input() dataKey: string;
  @Input() groupRowsBy: string;
  @Input() dados: LinhaTabelaSinalizadorProntidao[];
  @Input() useHierarquiaSort = false;
  @Input() dynamicTableRows = 30;
  @Input() showColunaHierarquia: boolean = false;
  @Input() configuracao: ResponseSinalizadorProntidaoConfiguracaoTO;
  @Input() podeExpandirHierarquias: boolean = true;

  isHierarquia: boolean;
  sortDirection: "up" | "down" = null;
  sortColumn: string;
  isGerdau = ClienteUtil.isGerdau();

  constructor(private notificationService: NotificationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customErroTabela?.currentValue) {
      this.dados = [];
    }
    if (changes.dados?.currentValue) {
      this.ordenarColuna(this.sortColumn);
    }
    if (changes.dataKey?.currentValue) {
      this.isHierarquia = this.dataKey == "idHierarquia";
    }
  }

  get showChildren() {
    return !!this.groupRowsBy;
  }

  private compareSortValues(value1, value2) {
    if (value1 == null && value2 != null) return -1;
    else if (value1 != null && value2 == null) return 1;
    else if (value1 == null && value2 == null) return 0;
    else if (typeof value1 === "string" && typeof value2 === "string") return value1.localeCompare(value2);
    else return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
  }

  onChangeOrdenacao(nomeColuna: string) {
    this.sortColumn = nomeColuna;
    if (this.sortDirection == "down") {
      this.sortDirection = "up";
    } else {
      this.sortDirection = "down";
    }
    this.ordenarColuna(nomeColuna);
  }

  ordenarColuna(nomeColuna: string) {
    if (!this.useHierarquiaSort) return;

    // ordena todos
    if (this.sortDirection) {
      this.dados.sort((data1, data2) => {
        const value1 = data1[nomeColuna];
        const value2 = data2[nomeColuna];
        const result = this.compareSortValues(value1, value2);
        const order = this.sortDirection == "down" ? 1 : -1;
        return order * result;
      });
    }

    //reagrupa as hierarquias e usuarios
    const usuarios = this.dados.filter((item) => !!item.idUsuario);
    const hierarquias: any[] = this.dados
      .filter((item) => !item.idUsuario)
      .map((h) => {
        h.usuarios = [];
        return h;
      });

    usuarios.forEach((user) => {
      const hierarquiaFinded = hierarquias.find((h) => h.idHierarquia == user.idHierarquia);
      if (hierarquiaFinded) {
        hierarquiaFinded.usuarios.push(user);
      }
    });

    const hierarquiasAbertas = hierarquias.reduce((prev, cur) => {
      return [...prev, cur, ...cur.usuarios];
    }, []);
    this.dados = hierarquiasAbertas;
  }

  getLoadingColspan() {
    let total = 13;
    if (!this.showColunaHierarquia) {
      total -= 1;
    }
    return total;
  }

  showSortIcon(colunaSort) {
    return this.sortColumn == colunaSort;
  }
}
