import { TranslateService } from "@ngx-translate/core";
import { SelectItem } from "../models/select-item.model";
import { Util } from "../services/util.service";
export enum TipoJustificativaAusenciaEnum {
  OUTROS = "OUTROS",
  FERIAS = "FERIAS",
  ATESTADO = "ATESTADO",
  AUSENCIA_JUSTIFICADA = "AUSENCIA_JUSTIFICADA",
  COMPENSACAO_HORAS = "COMPENSACAO_HORAS",
  FALTA = "FALTA",
  INTEGRACAO = "INTEGRACAO",
  TROCA_HORARIO = "TROCA_HORARIO",
}

export const getTipoJustificativasAusencia = (translateService: TranslateService): SelectItem[] => {
  const justificativas: SelectItem[] = [
    {
      label: translateService.instant(`avisoFerias.${TipoJustificativaAusenciaEnum.FERIAS}`),
      value: TipoJustificativaAusenciaEnum.FERIAS,
    },
    {
      label: translateService.instant(`avisoFerias.${TipoJustificativaAusenciaEnum.AUSENCIA_JUSTIFICADA}`),
      value: TipoJustificativaAusenciaEnum.AUSENCIA_JUSTIFICADA,
    },
    {
      label: translateService.instant(`avisoFerias.${TipoJustificativaAusenciaEnum.FALTA}`),
      value: TipoJustificativaAusenciaEnum.FALTA,
    },
    {
      label: translateService.instant(`avisoFerias.${TipoJustificativaAusenciaEnum.ATESTADO}`),
      value: TipoJustificativaAusenciaEnum.ATESTADO,
    },
    {
      label: translateService.instant(`avisoFerias.${TipoJustificativaAusenciaEnum.COMPENSACAO_HORAS}`),
      value: TipoJustificativaAusenciaEnum.COMPENSACAO_HORAS,
    },
    {
      label: translateService.instant(`avisoFerias.${TipoJustificativaAusenciaEnum.INTEGRACAO}`),
      value: TipoJustificativaAusenciaEnum.INTEGRACAO,
    },
    {
      label: translateService.instant(`avisoFerias.${TipoJustificativaAusenciaEnum.TROCA_HORARIO}`),
      value: TipoJustificativaAusenciaEnum.TROCA_HORARIO,
    },
  ];

  Util.ordenarAlfabetica(justificativas, "label");
  justificativas.push({
    label: translateService.instant(`avisoFerias.${TipoJustificativaAusenciaEnum.OUTROS}`),
    value: TipoJustificativaAusenciaEnum.OUTROS,
  });
  return justificativas;
};
