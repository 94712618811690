import { DatePipe, TitleCasePipe } from "@angular/common";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "angular-highcharts";
import Highcharts from "highcharts";
import { SeriesOptionsType } from "highcharts";
import moment from "moment";
import { SinalizadorProntidaoService } from "src/app/shared/api-services/sinalizador-prontidao.service";
import { ScoreColors } from "src/app/shared/enums/score-colors.enum";
import { getScoreClass } from "src/app/shared/services/score-class";
import { SharedModule } from "src/app/shared/shared.module";
import { ParamsSinalizadorProntidao } from "src/app/shared/tos/request/params-sinalizador-prontidao";
import { ResponseSinalizadorProntidaoCompiladoGrafico } from "src/app/shared/tos/response/response-sinalizador-prontidao-compilado-graficos.to";
import { ResponseSinalizadorProntidaoCompiladoHierarquia } from "src/app/shared/tos/response/response-sinalizador-prontidao-compilado-hierarquia.to";

@Component({
  selector: "app-compilado-graficos",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./compilado-graficos.component.html",
  styleUrl: "./compilado-graficos.component.scss",
  providers: [DatePipe, TitleCasePipe],
})
export class CompiladoGraficosComponent implements OnChanges {
  @Input() hierarquia: ResponseSinalizadorProntidaoCompiladoHierarquia;
  @Input() filtros: ParamsSinalizadorProntidao;
  @Input() variant: "inline" | "normal" = "normal";
  @Input() showDsHierarquia = false;
  loading: boolean;
  chartUsuariosFatorRisco: Chart;
  chartPorcentagemFatores: Chart;
  chartTemperaturaScore: Chart;
  chartIndiceProntidao: Chart;

  constructor(
    private sinalizadorProntidaoService: SinalizadorProntidaoService,
    private translateService: TranslateService,
    private titleCasePipe: TitleCasePipe,
    private datePipe: DatePipe,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hierarquia?.currentValue) {
      this.carregarGraficos();
    }
  }

  scoreClass(score) {
    return getScoreClass(score);
  }

  carregarGraficos() {
    this.loading = true;
    this.chartUsuariosFatorRisco = null;
    this.chartPorcentagemFatores = null;
    this.chartTemperaturaScore = null;
    this.chartIndiceProntidao = null;
    this.sinalizadorProntidaoService
      .getSinalizadorProntidaoCompiladoGraficos(this.hierarquia.idHierarquia, this.filtros)
      .subscribe(
        (response) => {
          this.loading = false;
          this.montarGraficoTemperaturaScore(this.hierarquia.qtScoreTotal);
          this.montarGraficoUsuariosPorFatorRisco(response);
          this.montarGraficoPorcentagemFatores(response);
          this.montarGraficoIndiceProntidao(response);
        },
        (err) => {
          this.loading = false;
          throw err;
        },
      );
  }

  montarGraficoUsuariosPorFatorRisco(dados: ResponseSinalizadorProntidaoCompiladoGrafico) {
    const series: SeriesOptionsType[] = [0, 1, 2, 3, 4, 5].map((index) => {
      return {
        name: `Score ${index}`,
        data: [dados["qtUsuarioScore" + index]],
        type: "column",
        borderColor: "none",
        borderRadius: 10,
        color: ScoreColors["Score" + index],
      };
    });
    this.chartUsuariosFatorRisco = new Chart({
      chart: {
        backgroundColor: "transparent",
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#BDBDBD",
        gridLineDashStyle: "ShortDash",
        categories: [],
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        gridLineColor: "#BDBDBD",
        gridLineDashStyle: "Dash",
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: "",
      },
      credits: {
        enabled: false,
      },
      series: series,
    });
  }

  montarGraficoPorcentagemFatores(dados: ResponseSinalizadorProntidaoCompiladoGrafico) {
    this.chartPorcentagemFatores = new Chart({
      chart: {
        backgroundColor: "transparent",
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#BDBDBD",
        gridLineDashStyle: "ShortDash",
        categories: [
          this.translateService.instant("sinalizador.compilado.fatorDesvio"),
          this.translateService.instant("sinalizador.compilado.fatorAcoes"),
          this.translateService.instant("sinalizador.compilado.fatorBemEstar"),
          this.translateService.instant("sinalizador.compilado.fatorPreditor"),
          this.translateService.instant("sinalizador.compilado.fatorFadiga"),
        ],
      },
      yAxis: {
        gridLineColor: "#BDBDBD",
        gridLineDashStyle: "Dash",
        labels: {
          format: "{text}%",
        },
        title: {
          text: "",
        },
      },
      plotOptions: {
        series: {
          color: "#455775",
        },
        bar: {
          borderRadius: 10,
        },
      },
      tooltip: {
        headerFormat: "",
        formatter: function () {
          return `${this.x}: <b>${Highcharts.numberFormat(this.y, 2)}%</b>`;
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: "bar",
          data: [
            dados.pcNivelAlterado * 100,
            dados.pcAcoesCriticas * 100,
            dados.pcQueixasUsuarios * 100,
            dados.pcPreditorRisco * 100,
            dados.pcFadiga * 100,
          ],
        },
      ],
    });
  }

  montarGraficoTemperaturaScore(score: number) {
    this.chartTemperaturaScore = new Chart({
      chart: {
        type: "gauge",
        backgroundColor: "transparent",
        height: "100%",
      },
      title: {
        text: "",
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
      },
      yAxis: {
        min: 0,
        max: 5,
        tickColor: "#f5f5f5",
        tickLength: 45,
        tickWidth: 5,
        minorTickInterval: null,
        labels: {
          distance: 15,
          style: {
            fontSize: "14px",
            fontWeight: "600",
          },
        },
        lineWidth: 0,
        plotBands: [
          {
            from: 0,
            to: 1,
            color: ScoreColors.Score1,
            thickness: 40,
          },
          {
            from: 1,
            to: 2,
            color: ScoreColors.Score2,
            thickness: 40,
          },
          {
            from: 2,
            to: 3,
            color: ScoreColors.Score3,
            thickness: 40,
          },
          {
            from: 3,
            to: 4,
            color: ScoreColors.Score4,
            thickness: 40,
          },
          {
            from: 4,
            to: 5,
            color: ScoreColors.Score5,
            thickness: 40,
          },
        ],
      },
      tooltip: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Score",
          type: "gauge",
          data: [score],
          dataLabels: {
            enabled: false,
          },
          dial: {
            radius: "50%",
            baseWidth: 12,
            baseLength: "0%",
            rearLength: "0%",
          },
          pivot: {
            radius: 10,
            borderWidth: 2,
            borderColor: "#f5f5f5",
          },
        },
      ],
    });
  }

  montarGraficoIndiceProntidao(dados: ResponseSinalizadorProntidaoCompiladoGrafico) {
    this.chartIndiceProntidao = new Chart({
      chart: {
        backgroundColor: "transparent",
        type: "line",
      },
      title: {
        text: "",
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#BDBDBD",
        gridLineDashStyle: "ShortDash",
        categories: dados.dadosGestaoAcao.map((d) =>
          this.titleCasePipe.transform(this.datePipe.transform(moment(d.dtMesAno).toDate(), "MMMM, y")),
        ),
      },
      yAxis: {
        gridLineColor: "#BDBDBD",
        gridLineDashStyle: "Dash",
        min: 0,
        max: 100,
        tickInterval: 25,
        title: {
          text: "",
        },
        labels: {
          format: "{text}%",
        },
      },
      tooltip: {
        formatter: function () {
          return `${this.series.name}<br/>${this.x}:<b>${Highcharts.numberFormat(this.y, 2)}%</b>`;
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: "line",
          name: this.translateService.instant("sinalizador.compilado.gestaoAcoes"),
          data: dados.dadosGestaoAcao.map((d) => d.pcMesAno * 100),
          color: "#00AEB8",
        },
        {
          type: "line",
          name: this.translateService.instant("sinalizador.compilado.prontidao"),
          data: dados.dadosProntidao.map((d) => d.pcMesAno * 100),
          color: "#637FAE",
        },
      ],
    });
  }
}
