import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { CompiladoHeaderComponent } from "./compilado-header/compilado-header.component";
import { CompiladoNavigationComponent } from "./compilado-navigation/compilado-navigation.component";
import { CompiladoCardComponent } from "./compilado-card/compilado-card.component";
import { ResponseSinalizadorProntidaoCompiladoHierarquia } from "src/app/shared/tos/response/response-sinalizador-prontidao-compilado-hierarquia.to";
import { CompiladoGraficosComponent } from "./compilado-graficos/compilado-graficos.component";
import { ParamsSinalizadorProntidao } from "src/app/shared/tos/request/params-sinalizador-prontidao";

@Component({
  selector: "app-compilado-sinalizador-prontidao",
  standalone: true,
  imports: [
    SharedModule,
    CompiladoHeaderComponent,
    CompiladoNavigationComponent,
    CompiladoCardComponent,
    CompiladoGraficosComponent,
  ],
  templateUrl: "./compilado-sinalizador-prontidao.component.html",
  styleUrl: "./compilado-sinalizador-prontidao.component.scss",
})
export class CompiladoSinalizadorProntidaoComponent implements OnInit, OnChanges {
  @Input() loading: boolean;
  @Input() hierarquiaNivel1: ResponseSinalizadorProntidaoCompiladoHierarquia;
  @Input() filtros: ParamsSinalizadorProntidao;
  hierarquiaNivel2: ResponseSinalizadorProntidaoCompiladoHierarquia;
  hierarquiaDetalhes: ResponseSinalizadorProntidaoCompiladoHierarquia;
  showPopupGraficos: boolean;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hierarquiaNivel1?.currentValue) {
      this.hierarquiaNivel2 = this.hierarquiaNivel1?.hierarquiasFilhas?.[0];
    }
  }

  abrirDetalhesScore(hierarquia: ResponseSinalizadorProntidaoCompiladoHierarquia) {
    this.showPopupGraficos = true;
    this.hierarquiaDetalhes = hierarquia;
  }
}
