import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { FiltroGraficoDashboad } from "src/app/shared/models/filtro-grafico-dashboard";
import { Util } from "src/app/shared/services/util.service";
import { getValoresEnumTraduzidosFormacaoBancoDados } from "src/app/shared/enums/formacao-banco-dados.enum";
import { SelectItem, TreeNode } from "primeng/api";
import { HierarquiaService } from "src/app/shared/api-services/hierarquia.service";
import { TurnoService } from "src/app/shared/api-services/turno.service";
import { CargoService } from "src/app/shared/api-services/cargo.service";
import { ResponseHierarquiaTO } from "src/app/shared/tos/response-hierarquia.to";
import { ResponseHierarquiaSimplesTO } from "src/app/shared/tos/response-hierarquia-simples.to";
import { CriterioInsercaoService } from "src/app/shared/services/criterio-insercao.service";
import moment from "moment";
import { ToastService } from "src/app/shared/services/toast.service";
import { getListaScoreFadigaEnum } from "src/app/shared/enums/score-fadiga.enum";

@Component({
  selector: "app-filtros-dashboard",
  templateUrl: "./filtros-dashboard.component.html",
  styleUrls: ["./filtros-dashboard.component.scss"],
})
export class FiltrosDashboardComponent implements OnInit {
  @Output() filtrar = new EventEmitter<FiltroGraficoDashboad>();
  @Input() loading = false;
  @Input() isFiltrosIndicadorFadiga = false;

  showDialogFiltroGrafico: boolean = false;
  listaFormacaoBancoDados: SelectItem[] = [];
  listaTurnos: SelectItem[] = [];
  listaCargos: SelectItem[] = [];
  listaScoreFadiga: SelectItem[] = [];
  todasHierarquias: ResponseHierarquiaTO[];
  hierarquias: TreeNode[] = [];
  checkboxApenasUsuariosAtivos: boolean = false;
  exibirFiltroMatricula: boolean = true;
  minimoDiaMes: any;
  yearRange = new Date().getFullYear() - 3 + ":" + new Date().getFullYear();
  criteriosInsercaoPreDefinidos: any[];

  private selectsCarregados = false;

  private _dadosFiltroGraficoDashboard: FiltroGraficoDashboad;
  constructor(
    private keyCloakService: KeycloakService,
    private translateService: TranslateService,
    private hierarquiaService: HierarquiaService,
    private turnoService: TurnoService,
    private cargoService: CargoService,
    private render2: Renderer2,
    private criterioInsercaoService: CriterioInsercaoService,
    private toastService: ToastService
  ) {}

  @Input() set dadosFiltroGraficoDashboard(value: FiltroGraficoDashboad) {
    this._dadosFiltroGraficoDashboard = new FiltroGraficoDashboad().formatarFiltros(value);
  }

  get dadosFiltroGraficoDashboard() {
    return this._dadosFiltroGraficoDashboard;
  }

  ngOnInit() {
    this.utilizarCriterioInsercaoPreDefinido();
    this.carregarDataMinima();
    this.onChangeApenasUsuariosAtivos();
    this.handleSubmit();
  }

  utilizarCriterioInsercaoPreDefinido() {
    this.criteriosInsercaoPreDefinidos = this.criterioInsercaoService.getValoresPreDefinidos();
  }

  carregarSelects() {
    const formacaoBancoDados = getValoresEnumTraduzidosFormacaoBancoDados(this.translateService);
    this.listaFormacaoBancoDados = Util.jsonArrayToSelectItem(
      formacaoBancoDados,
      "label",
      { label: this.translateService.instant("todos"), value: null },
      "id"
    );
    this.listaScoreFadiga = Util.jsonArrayToSelectItem(
      getListaScoreFadigaEnum(this.translateService),
      "label",
      null,
      "value"
    );
    this.carregarHierarquiaTurnoCargo();
    this.selectsCarregados = true;
  }

  private async carregarHierarquiaTurnoCargo() {
    try {
      const result = await Promise.all([
        this.hierarquiaService.getAll().toPromise(),
        this.turnoService.getByHierarquia().toPromise(),
        this.cargoService.getArvoreHierarquia().toPromise(),
      ]);
      this.todasHierarquias = JSON.parse(JSON.stringify(result[0]));
      this.hierarquias = this.hierarquiaService.createTreeView(result[0]);
      if (!this.dadosFiltroGraficoDashboard?.idHierarquia?.length) {
        const idsTemorarios = this.hierarquiaService.getHierarquiasUsuario();
        this.dadosFiltroGraficoDashboard.idHierarquia = idsTemorarios;
      }
      this.listaTurnos = Util.jsonArrayToSelectItem(result[1], "dsTurno", null, "id");
      this.listaCargos = Util.jsonArrayToSelectItem(result[2], "dsCargo", null, "id");
    } catch (err) {
      console.log("Erro ao carregar hierarquia, turno e cargo", err);
      throw err;
    }
  }

  handleSubmit() {
    const filtros = this.dadosFiltroGraficoDashboard.toApi();
    if (moment(filtros.dtInicio).isAfter(filtros.dtFim)) {
      this.toastService.notifyWithTranslateError("dashboard.erroDataFiltro");
      return;
    }
    if (this.dadosFiltroGraficoDashboard?.idHierarquia?.length) {
      this.filtrar.emit(filtros);
    }
  }

  isFormValid() {
    return this.dadosFiltroGraficoDashboard?.idHierarquia?.length;
  }

  showFiltros() {
    this.showDialogFiltroGrafico = true;
    if (this.selectsCarregados == false) {
      this.carregarSelects();
    }
  }

  fecharDialogoFiltroGrafico() {
    this.showDialogFiltroGrafico = false;
  }

  onChangeApenasUsuariosAtivos() {
    this.dadosFiltroGraficoDashboard.flApenasUsuarioAtivos = !this.checkboxApenasUsuariosAtivos;
  }

  // TODO refatorar isso aqui
  abrirHierarquiasFiltroDashboard(op: any, event): void {
    op.toggle(event);
    setTimeout((_) => {
      const containerHierarquia: any = document.querySelector(".hierarquiaFiltroDashboard > div");
      const tamanhoContainerHierarquia: number = document.querySelector(
        "#idContainerHierarquiaFiltroDashboard"
      ).clientWidth;
      this.render2.setStyle(containerHierarquia, "width", `${tamanhoContainerHierarquia}px`);
      this.render2.setStyle(containerHierarquia, "width", tamanhoContainerHierarquia);
      this.render2.setStyle(containerHierarquia, "overflow", "auto");
    }, 0);
  }

  carregarDataMinima() {
    const isAdministrator = this.keyCloakService
      .getUserRoles()
      .some((userPermission) => userPermission === "RL_ADMINISTRADOR");
    const isGestorSME = this.keyCloakService
      .getUserRoles()
      .some((userPermission) => userPermission === "RL_GESTOR_SME");
    if (isAdministrator || isGestorSME) {
      const date = new Date();
      this.minimoDiaMes = new Date(date.getFullYear() - 3, date.getMonth(), date.getDay());
    } else {
      this.minimoDiaMes = Util.subtrairDiasInData(new Date(), 90);
    }
  }
}
