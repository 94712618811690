import { Component, Input } from "@angular/core";
import { SharedModule } from "../../../../../../shared/shared.module";
import { TranslateService } from "@ngx-translate/core";
import { ResponseSinalizadorProntidaoCompiladoHierarquia } from "src/app/shared/tos/response/response-sinalizador-prontidao-compilado-hierarquia.to";
import { getScoreClass } from "src/app/shared/services/score-class";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-compilado-card-item",
  standalone: true,
  imports: [SharedModule, NgClass],
  templateUrl: "./compilado-card-item.component.html",
  styleUrl: "./compilado-card-item.component.scss",
})
export class CompiladoCardItemComponent {
  @Input() hierarquiaNivel4: ResponseSinalizadorProntidaoCompiladoHierarquia;

  constructor(private translateService: TranslateService) {}

  scoreClass(score, onlyBorder) {
    return getScoreClass(score, onlyBorder);
  }
}
