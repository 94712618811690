<div class="card-item-container">
  <div class="header">
    <h5 class="titulo">{{ hierarquiaNivel4.dsHierarquia }}</h5>
    <div class="score-circle" [ngClass]="scoreClass(hierarquiaNivel4.qtScoreTotal, true)">
      {{ hierarquiaNivel4.qtScoreTotal }}
    </div>
  </div>
  <div class="conteudo">
    <div class="conteudo-titulo">Supervisões</div>
    <p-table styleClass="p-datatable-striped" [value]="hierarquiaNivel4.hierarquiasFilhas">
      <ng-template pTemplate="header">
        <tr>
          <th translate>sinalizador.compilado.nome</th>
          <th translate class="text-right">sinalizador.compilado.score</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{ item.dsHierarquia }}</td>
          <td>
            <div class="score-badge" [ngClass]="scoreClass(item.qtScoreTotal, false)">
              {{ item.qtScoreTotal || 0 }}
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2" translate>sinalizador.compilado.semSupervisao</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
