export const getScoreClass = (score: number, onlyBorder = false) => {
  let prefix = onlyBorder ? "border-" : "";

  if (!score || score <= 0) {
    return `--${prefix}score0`;
  }

  if (score >= 5) {
    return `--${prefix}score5`;
  }

  return `--${prefix}score` + score;
};
