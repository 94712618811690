<div>
  <p translate class="titulo">sinalizador.compilado.analiseScore</p>
  @if (showDsHierarquia) {
    <p class="dsHierarquia">{{ hierarquia.dsHierarquia }}</p>
  }
  <p translate class="subtitulo">sinalizador.compilado.detalhesScoreSubtitulo</p>

  <div class="graficos-container" [ngClass]="variant">
    <div class="grafico-item">
      <div class="grafico-header">
        <p class="grafico-titulo">
          <span translate> sinalizador.compilado.escoreGlobal </span> {{ hierarquia.qtScoreTotal }}
        </p>
        <p class="grafico-subtitulo" translate>sinalizador.compilado.escoreGlobalSubtitulo</p>
      </div>
      <app-loader [loading]="loading" type="normal" />
      @if (chartTemperaturaScore) {
        <div [chart]="chartTemperaturaScore" class="grafico"></div>
        <div class="score-badge" [ngClass]="scoreClass(hierarquia?.qtScoreTotal)">
          <span translate> sinalizador.compilado.risco </span>
          = {{ hierarquia?.qtScoreTotal || 0 }}
        </div>
      }
    </div>

    <div class="grafico-item">
      <div class="grafico-header">
        <p class="grafico-titulo"><span translate> sinalizador.compilado.usuariosFatorRisco </span></p>
        <p class="grafico-subtitulo" translate>sinalizador.compilado.usuariosFatorRiscoSubtitulo</p>
      </div>
      <app-loader [loading]="loading" type="normal" />
      @if (chartUsuariosFatorRisco) {
        <div [chart]="chartUsuariosFatorRisco" class="grafico"></div>
      }
    </div>

    <div class="grafico-item">
      <div class="grafico-header">
        <p class="grafico-titulo"><span translate> sinalizador.compilado.fatores </span></p>
        <p class="grafico-subtitulo" translate>sinalizador.compilado.fatoresSubtitulo</p>
      </div>
      <app-loader [loading]="loading" type="normal" />
      @if (chartPorcentagemFatores) {
        <div [chart]="chartPorcentagemFatores" class="grafico"></div>
      }
    </div>

    <div class="grafico-item">
      <div class="grafico-header">
        <p class="grafico-titulo"><span translate> sinalizador.compilado.indiceGestaoProntidao </span></p>
        <p class="grafico-subtitulo" translate>sinalizador.compilado.indiceGestaoProntidaoSubtitulo</p>
      </div>
      <app-loader [loading]="loading" type="normal" />
      @if (chartIndiceProntidao) {
        <div [chart]="chartIndiceProntidao" class="grafico"></div>
      }
    </div>
  </div>
</div>
