<p-button
  class="download-button"
  [label]="'botaoDownload' | translate"
  icon="pi pi-download"
  styleClass="sme-secondary"
  [disabled]="loading"
  (onClick)="downloadSinalizadorProntidao()"
  [loading]="loading"
>
</p-button>
